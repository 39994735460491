import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: () => import('../views/login.vue'),
  },
  {
    
      path: "/layout",
      name: "layout",
      component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
    children: [
      { path: '/dashboard', component: () => import('../views/dashboard.vue') },
      { path: '/createcustomer', component: () => import('../views/createcustomer.vue') },
      { path: '/customer', component: () => import('../views/customer.vue') },
      { path: '/loan', component: () => import('../views/loan.vue') },
      { path: '/others', component: () => import('../views/others.vue') },
      { path: '/createloan', component: () => import('../views/createloan.vue') },
      { path: '/createproject', component: () => import('../views/createproject.vue') },
      { path: '/digilockerVerification', component: () => import('../views/digilockerVerification.vue') },
      { path: '/profileCustomer', component: () => import('../views/profileCustomer.vue') },
      { path: '/collections', component: () => import('../views/collections.vue') },
      { path: '/loanupdate', component: () => import('../views/loanupdate.vue') },
      { path: '/admin', component: () => import('../views/admin.vue') },
      { path: '/master', component: () => import('../views/master.vue') },
      { path: '/createadmin', component: () => import('../views/createadmin.vue') },
      // digilockerverification
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
